export const pageWrapperStyle = {
  flex: 1,
  width: '100%',
  height: '100%'
}

export const backgroundImageStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
}

export const colors = {
  white:      '#FFFFFF',
  black:      '#000000',
  overlay:    'rgba(5, 19, 22, 0.8)',

  shade1:     '#a7adaf',                // slightly blue dark grey
  shade2:     '#d4d9db',                // slightly blue medium grey
  shade3:     '#f1f3f4',                // slightly blue light grey

  text1:      '#5f6368',                // slightly blue dark grey text/icon
  text2:      '#80868b',                // slightly blue medium grey text/icon
  text3:      '#b8b8b8',                // slightly blue light grey text/icon

  nav1:       '#005776',                // primary blue
  nav2:       '#00465e',                // dark primary blue

  primary1:   'rgb(250,70,22)',         // primary red-orange
  primary2:   'rgba(250,70,22,0.4)',    // primary red-orange w/ alpha
  primary3:   'rgb(253,181,162)',       // lighter primary red-orange
  primary4:   'rgb(200, 51, 5)',        // darker primary red-orange

  secondary:  'rgb(45,204,211)',        // secondary teal

  danger1:    'rgb(255,198,0)',         // danger yellow
  danger2:    'rgb(255,232,153)',       // danger yellow
  success:    'rgb(71,162,63)',         // success green

  tertiary1:   '#a2aaad',               // slightly blue grey

  charcoal:       '#4D4D4D',
  gray:           '#A2AAAD',
  lightGray1:     '#EBEBEB',
  lightGray2:     '#F4F4F4',
  deepBlue:       '#005776',
  deepBlueTint:   '#408199',
  skyBlue:        '#2DCCD3',
  skyBlueShade:   '#32A6AC',
  skyBlueTint1:   '#EAF9FA',
  skyBlueTint2:   '#D9F3F4',
  redOrange:      '#FA4616',
  darkRedOrange:  '#913D26',
  yellow:         '#FFC600',
  green:          '#47A23F',
}

// util for converting CSS rgb to a value string for use in WC variables
const convertColor = cssStr => {
  // variable a is defined only as a placeholder
  // eslint-disable-next-line
  const [a, rgb] = cssStr.match(/\((\d+\,\s*\d+\,\s*\d+(\,\s*\d+)?)\)/) || []

  return rgb
}

export const unityVars = {
  /* Input text color */
  '--black-color': colors.black,

  /* Default background color, Font Color */
  '--white-color': colors.white,

  /* dark grey, text default */
  '--dark-gray-color': colors.charcoal,

  /* normal gray, border default, disabled text */
  '--gray-color': colors.gray,

  /* light gray 1, disabled content */
  '--light-gray-1-color': colors.lightGray1,

  /* light gray 2, disabled content */
  '--light-gray-2-color': colors.lightGray2,

  /* default brand color, switches, radios, checkboxes, focus */
  '--primary-color': colors.skyBlue,

  /* darked primary color */
  '--primary-shade-color': colors.skyBlueShade,

  /* faint primary highlight */
  '--primary-tint-1-color': colors.skyBlueTint1,

  /* primary highlight */
  '--primary-tint-2-color': colors.skyBlueTint2,

  /* secondary brand color, buttons, tabs, links */
  '--secondary-color': colors.deepBlue,

  /* muted secondary color, clicked button */
  '--secondary-tint-color': colors.deepBlueTint,

  /* utility color, error, alert, attention grabbing, present/past-tense */
  '--tertiary-1-color': colors.redOrange,

  /* darker tertiary 1, pressed alert button */
  '--tertiary-1-shade-color': colors.darkRedOrange,

  /* utility color, warning, alert, future/present-tense */
  '--tertiary-2-color': colors.yellow,

  /* utility color, success, notification */
  '--tertiary-3-color': colors.green,

  /* Old Colors */
  '--primary-brand-rgb': convertColor(colors.primary1),
  '--secondary-brand-rgb': convertColor(colors.secondary),
  '--selection-opacity': 0.5,
  '--primary-brand-rgb-light': convertColor(colors.primary3),
  '--primary-brand-rgb-dark': convertColor(colors.primary4),

  /* Colors*/
  '--primary-brand-color': 'rgb(var(--primary-brand-rgb))',
  '--secondary-brand-color': 'rgb(var(--secondary-brand-rgb))',
  // '--primary-brand-color-light': 'rgb(var(--primary-brand-rgb-light))',
  '--primary-brand-color-dark': 'rgb(var(--primary-brand-rgb-dark))',
  '--success-color': colors.success,
  '--danger-color': colors.danger1,
  '--danger-lite-color': colors.danger2,
  '--background-color': 'white',
  // '--global-nav-background-color': colors.white,
  // '--global-nav-expanded-color': colors.white,
  // '--global-nav-border-color': colors.tertiary,
  // '--global-nav-text-color': colors.white,
  '--black-text-color': colors.black,
  '--dark-grey-text-color': colors.text1,
  '--medium-grey-text-color': colors.text2,
  '--light-grey-text-color': colors.text3,
  '--dark-grey-background-color': colors.shade1,
  '--medium-grey-background-color': colors.shade2,
  '--light-grey-background-color': colors.shade3,

  /* Fonts */
  '--font-family': 'Noto',

  '--header1-font-size': '18px',
  '--header1-font-weight': '400',

  '--header2-font-size': '15px',
  '--header2-font-weight': '400',

  '--header2-selected-font-size': '15px',
  '--header2-selected-font-weight': '600',

  '--paragraph-font-size': '11px',
  '--paragraph-font-weight': '400',

  /* this font size should likely change */
  '--small-text-size': '11px',
  '--small-text-weight': '400',

  '--small-text-selected-size': '11px',
  '--small-text-selected-weight': '600',
  '--unity-button-height': '27px',

  '--padding-size-sm': '8px'
}


export const muiShadows = {
  '1dp': '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  '2dp': '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  '3dp': '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
  '4dp': '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  '5dp': '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
  '6dp': '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  '7dp': '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
  '8dp': '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  '9dp': '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
  '10dp': '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
  '11dp': '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
  '12dp': '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
  '13dp': '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
  '14dp': '0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
  '15dp': '0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
  '16dp': '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
  '17dp': '0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
  '18dp': '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
  '19dp': '0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
  '20dp': '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
  '21dp': '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
  '22dp': '0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
  '23dp': '0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
  '24dp': '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
}

export const shadows = {
  switch: muiShadows['1dp'],
  spinner: muiShadows['3dp'],
  card: {
    raised: muiShadows['8dp'],
    resting: muiShadows['1dp']
  },
  toaster: muiShadows['6dp'],
  button: {
    raised: muiShadows['8dp'],
    resting: muiShadows['2dp']
  },
  modal: muiShadows['16dp'],
  picker: muiShadows['24dp'],
  header: muiShadows['4dp']
}

export const modalProps = {
  style: {
    backgroundColor: 'transparent'
  }
}

export const fieldLabel = {
  color: colors.shade3,
  fontSize: 12
}

export const fieldFooter = {
  color: colors.shade3,
  fontSize: 10
}

export const hyperlink = {
  color: colors.primary2,
  textDecorationLine: 'underline'
}

export const nodeWrapper = {
  backgroundColor: colors.white,
}

export const nodeContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  backgroundColor: colors.white,
  paddingTop: 5,
  paddingBottom: 5
}

const barWidth = 16
const barPadding = 16
export const nodeBar = {
  flex: 1,
  maxWidth: barWidth,
  borderRadius: barWidth,
  backgroundColor: colors.shade1,
  borderWidth: 0,
  marginRight: 0,
  marginLeft: barPadding
}

export const nodeInnerContainer = {
  flex: 1
}

export const detailsWrapper = {
  flex: 0,
  paddingBottom: 20
}

export const detailsContainer = {
  container: {
    height: undefined,
    maxHeight: undefined,
    minHeight: 45
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 2,
    paddingBottom: 2
  },
  titleBtnContent: {
    padding: '8px 18px',
    display: 'flex',
    flexAlign: 'center',
    flexDirection: 'row'
  },
  titleBtn: {
    marginLeft: -8,
    // padding: 8,
    height: 40,
    boxShadow: shadows.card.resting,
    backgroundColor: colors.white,
    borderRadius: 3,
    margin: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleNoBtn: {
    boxShadow: '0 0'

  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    fontFamily: 'var(--font-family)'
  },
  titleLinked: {
    color: colors.shade2,
    fontSize: 10,
    fontFamily: 'var(--font-family)',
    marginLeft: 10
  },
  leftIconWrapper: {
    width: barWidth,
    height: barWidth,
    backgroundColor: colors.shade1,
    borderRadius: barWidth,
    marginRight: barPadding,
  },
  leftElementContainer: {
    marginRight: 0,
    marginLeft: 0
  },
  rightElementContainer: {
    marginLeft: 5,
    marginRight: 5
  },
  centerElementContainer: {
    marginLeft: 0
  }
}

export const highlighterStyle = {
  backgroundColor: colors.primary1a
}

export const previewImage = {
  width: 30,
  height: 30
}

export const listItemTitle = {
  fontSize: 10,
  fontWeight: '100'
}

export const listItemContainer = {
  height: 45
}

export const backIcon = {
  width: 30,
  zIndex: 2
}

export const actionBtnPadding = 75
