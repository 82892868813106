import React from "react";
import AsyncApiComponent from "@asyncapi/react-component";
import "@asyncapi/react-component/lib/styles/fiori.css";

//MQTTtopics UI
import asyncapiYaml from "api/asyncapi_openapi.yaml";

const MQTTtopics = () => {
  return (
     <AsyncApiComponent schema={{ url: asyncapiYaml }} />
  );
};



export default MQTTtopics;
