import React from "react";
import AppContext from "components/AppContext/AppContext";

const navConfig = {
  top: [
    {
      key: "cloud/anything-db",
      label: "AnythingDB",
      icon: "device:widgets",
      short: false,
    },
    {
      key: "cloud/functions",
      label: "User Functions",
      icon: "unity:numeric_formula",
      short: false,
    },
    {
      key: "cloud/edge-ops",
      label: "Edge Ops",
      icon: "unity:server",
      short: false,
      children: [
        {
          key: "cloud/edge-ops/cluster-management",
          label: "Cluster Management",
          icon: "unity:db",
        },
        {
          key: "cloud/edge-ops/cluster-configuration",
          label: "Cluster Configuration",
          icon: "unity:cube",
        },
        // {
        //   key: "cloud/edge-ops/installer-images",
        //   label: "Installer Images",
        //   icon: "unity:db",
        // },
      ],
    },
    {
      key: "cloud/access-control",
      label: "Access Control",
      icon: "unity:token",
      short: false,
      children: [
        {
          key: "cloud/access-control/users",
          label: "Users",
          icon: "unity:user_groups",
        },
        {
          key: "cloud/access-control/roles",
          label: "Roles",
          icon: "unity:user_admin",
        },
        {
          key: "cloud/access-control/apps",
          label: "Apps",
          icon: "unity:magic_wand",
        },
        {
          key: "cloud/access-control/login",
          label: "Login",
          icon: "unity:step_in",
        },
      ],
    },
    {
      key: "cloud/spaces",
      label: "Spaces",
      icon: "unity:load_profile",
      short: false,
    },
    {
      key: "cloud/object-storage",
      label: "Object Storage",
      icon: "unity:cloud",
      short: false,
    },
    {
      key: "cloud/labels",
      label: "Labels",
      icon: "unity:flag",
      short: false,
    },
    {
      key: "cloud/spaceSettings",
      label: "Space Settings",
      icon: "unity:gear",
      short: false,
      children: [
        {
          key: "cloud/spaceSettings/secrets",
          label: "Secrets",
          icon: "unity:token",
          short: false,
        },
        {
          key: "cloud/spaceSettings/variables",
          label: "Variables",
          icon: "unity:token",
          short: false,
        },
      ],
    },
    {
      key: "cloud/mqttTopics",
      label: "MQTT Topics",
      icon: "unity:message",
      short: false,
    },
  ],
  bottom: [
    {
      key: "ecp",
      label: "ECP Docs",
      icon: "unity:document",
      short: true,
    },
  ],
};

const CloudApi = ({ children }) => {
  return <AppContext navConfig={navConfig}>{children}</AppContext>;
};

export default CloudApi;
