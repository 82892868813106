import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "../../assets/stylesheets/custom-swagger.css";
import { scopesPreset } from "utils/presets";

//YAML definitions
//AnythingDB
import anythingDBYaml from "api/anythingdb_openapi.yaml";

//RealtimeDB
import realtimeDBYaml from "api/realtimedb_openapi.yaml";

//Functions
import functionsYaml from "api/functions_openapi.yaml";

//Clusters
import clusterManagementYaml from "api/clustermanagement_openapi.yaml";
import clusterConfigurationYaml from "api/clusterconfiguration_openapi.yaml";

//Edge Ops
import ecpImagesYaml from "api/ecpimages_openapi.yaml";

//Access Control
import usersYaml from "api/users_openapi.yaml";
import rolesYaml from "api/roles_openapi.yaml";
import appsYaml from "api/apps_openapi.yaml";
import loginYaml from "api/login_openapi.yaml";

//Spaces
import spacesYaml from "api/spaces_openapi.yaml";

//Object Storage
import objectStorageYaml from "api/objectstorage_openapi.yaml";

//Labels
import labelsYaml from "api/labels_openapi.yaml";

// Secrets
import secretsYaml from "api/secrets_openapi.yaml";

// Variables
import variablesYaml from 'api/variables_openapi.yaml'

// -------------------

//ECP api
import ecpAuthYaml from "api/ecp/auth.yaml";
import ecpExportMqttYaml from "api/ecp/export-mqtt.yaml";
import ecpExportGooglePubsubYaml from "api/ecp/export-google-pubsub.yaml";
import ecpHealthYaml from "api/ecp/health.yaml";
import ecpLoggingYaml from "api/ecp/logging.yaml";
import ecpReportsYaml from "api/ecp/reports.yaml";
import ecpLabelYaml from "api/ecp/support-label.yaml";
import ecpThingsYaml from "api/ecp/things.yaml";
import cloudConnectYaml from "api/ecp/cloud-connect.yaml";
import driversOpcuaYaml from "api/ecp/drivers-opcua.yaml";
import ecpDriversLorawanYaml from "api/ecp/drivers-lorawan.yaml"
import ecpDriversMQTTYaml from "api/ecp/drivers-mqtt.yaml"
import supportHistoricDataYaml from "api/ecp/historic_openapi.yaml";
import supportRapidMinerYaml from "api/ecp/support-rapidminer.yaml";
import supportSeldonYaml from "api/ecp/support-seldon.yaml";
import supportSmartWorksYaml from "api/ecp/support-smartworks.yaml";
import supportIoTStudioYaml from "api/ecp/support-iot-studio.yaml";
import supportRulesYaml from "api/ecp/support-rules.yaml";
import netConfigYaml from "api/ecp/net-config.yaml";


const getYamlfromPath = (pathname) => {
  switch (pathname) {
    //Anything DB
    case "/cloud/anything-db":
      return anythingDBYaml;

    //Realtime DB
    case "/cloud/realtime-db":
      return realtimeDBYaml;

    //Functions
    case "/cloud/functions":
      return functionsYaml;

    //EdgeOps API
    case "/cloud/edge-ops/cluster-management":
      return clusterManagementYaml;
    case "/cloud/edge-ops/cluster-configuration":
      return clusterConfigurationYaml;
    case "/cloud/edge-ops/installer-images":
      return ecpImagesYaml;

    //Access Control
    case "/cloud/access-control/users":
      return usersYaml;
    case "/cloud/access-control/roles":
      return rolesYaml;
    case "/cloud/access-control/apps":
      return appsYaml;
    case "/cloud/access-control/login":
      return loginYaml;

    //Spaces
    case "/cloud/spaces":
      return spacesYaml;

    //Object Storage
    case "/cloud/object-storage":
      return objectStorageYaml;

    //Labels
    case "/cloud/labels":
      return labelsYaml;

    // Secrets
    case "/cloud/spaceSettings/secrets":
      return secretsYaml;

    case "/cloud/spaceSettings/variables":
      return variablesYaml;

    //ECP API
    case "/ecp/auth":
      return ecpAuthYaml;
    case "/ecp/export-mqtt":
      return ecpExportMqttYaml;
    case "/ecp/export-google-pubsub":
      return ecpExportGooglePubsubYaml;
    case "/ecp/health":
      return ecpHealthYaml;
    case "/ecp/logging":
      return ecpLoggingYaml;
    case "/ecp/reports":
      return ecpReportsYaml;
    case "/ecp/labels":
      return ecpLabelYaml;
    case "/ecp/things":
      return ecpThingsYaml;
    case "/ecp/cloud-connect":
      return cloudConnectYaml;
    case "/ecp/drivers-opcua":
      return driversOpcuaYaml;
    case "/ecp/drivers-lorawan":
      return ecpDriversLorawanYaml;
    case "/ecp/drivers-mqtt":
      return ecpDriversMQTTYaml;
    case "/ecp/support-historic-data":
      return supportHistoricDataYaml;
    case "/ecp/support-rapidminer":
      return supportRapidMinerYaml;
    case "/ecp/support-seldon":
      return supportSeldonYaml;
    case "/ecp/support-smartworks":
      return supportSmartWorksYaml;
    case "/ecp/support-iot-studio":
      return supportIoTStudioYaml;
    case "/ecp/support-rules":
      return supportRulesYaml;
    case "/ecp/network-configuration":
      return netConfigYaml;

    default:
      return "";
  }
};

function SWXDoc(props) {
  const { location: { pathname } = {} } = props;
  const yamlSrc = getYamlfromPath(pathname);

  return (
    <div style={styles.container}>
      <SwaggerUI
        deepLinking
        presets={[
          scopesPreset
        ]}
        url={yamlSrc}
      />
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: 1,
  },
};

export default SWXDoc;
