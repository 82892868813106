import {
  addNotification,
  closeNotification,
  clearNotifications
} from '@bit/smartworks.unity-react.unity-core-react'

// === CONSTANTS ===
export const GLOBAL_NOTIFICATIONS = 'global-notifications'
export const MESSAGE_TYPE_ERROR = 'error'
export const MESSAGE_TYPE_WARNING = 'warning'
export const MESSAGE_TYPE_SUCCESS = 'success'
export const MESSAGE_TYPE_HELP = 'help'
export const MESSAGE_TYPE_TIP = 'tip'

// === MESSAGE MGMT UTILS ===
export const addMessage = ({ target=GLOBAL_NOTIFICATIONS, api='', type=MESSAGE_TYPE_TIP, text='', subtext='', timeout=0 }) => {
  const message = {
    text: text || changeApiMessageWording(getMessageFromApiResponse(api)),
    subtext: subtext || getSubtextFromApiMessage(api)
  }
  return addNotification({
    target,
    notification: {
      ...message,
      timeout,
      type
    }
  })
}
export const nextMessage = (target) => closeNotification(target)
export const clearMessages = (target) => clearNotifications(target)

// === HELPER FUNCTIONS ===
// format error message from api response
const getMessageFromApiResponse = (resp) => {
  const {
    meta: {
      messages
    }={},
    errors: {
      _error: apiErr=''
    }={}
  } = resp || {}
  if (resp instanceof TypeError)
    return resp.message
  if (Array.isArray(messages))
    return messages.map(({ text='' }) => text).join('\n')
  if (typeof messages === 'object')
    return messages.text
  if (!!apiErr)
    return typeof(apiErr) === 'object' ? JSON.stringify(apiErr) : apiErr
  return
}

const changeApiMessageWording = (messageText) => { 
  // Use this function to change wording according to PM requirements
  switch(messageText) {
     default:
      return messageText
  }
}

const getSubtextFromApiMessage = (messageText) => {
  // Use this function to change wording according to PM requirements
  switch(messageText) {
    default:
     return messageText
  }
}
