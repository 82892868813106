import React from 'react';
import { withRouter} from 'react-router-dom'
import {
  UnityGlobalNav
} from '@bit/smartworks.unity-react.unity-core-react'
import LogoIcon from 'assets/images/favicon.ico'

const TITLE = 'User Manual'

const AppContext = ({
  navConfig,
  children,
  location: {pathname: location}={},
  history,
  title=TITLE
}) => {
  const section = location.slice(1)

  return (
    <div className="main" style={styles.mainContainer}>
      <div className="nav-container" style={styles.navWrapper}>
        <UnityGlobalNav
          header={title}
          logo={LogoIcon}
          items={navConfig}
          selected={section}
          collapsible
          bubbleBottomItems
          onSelect={(key)=>  history.push(`/${key}`)}
          style={styles.navContainer}
        />
      </div>
      <div className="view" style={styles.viewContainer}>
        {children}
      </div>
    </div>
  )
}

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1,
    position: "relative",
    height: "100%",
    width: "100%",
  },
  navWrapper: {
    alignSelf: "stretch",
    display: "flex",
    zIndex: 100,
  },
  navContainer: {
    flex: 1,
    position: "relative",
    alignSelf: "stretch",
    "--global-nav-menu-shadow": "none",
    "--global-nav-expanded-width": "220px",
  },
  viewContainer: {
    flex: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  pageHeader: {
    "--page-header-border": "none",
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 16px 6px 12px",
    borderBottom: `1px solid #A2AAAD`, //In studio, this is colors.gray
  },
  "subHeader:hover": {
    backgroundColor: "#EAF9FA",
    cursor: "pointer",
  },
};
export default withRouter(AppContext);
