import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import CloudApi from "components/CloudApi/CloudApi";
import EcpApi from "components/EcpApi/EcpApi";
import SWXDoc from "components/SWXDoc/SWXDoc";
import MQTTtopics from "components/MQTTtopicsUI/MQTTtopics";

export default (
  <Switch>
    <Route path="/cloud">
      <CloudApi>
        <Switch>
          <Route path="/cloud/anything-db" component={SWXDoc} />

          <Route path="/cloud/realtime-db" component={SWXDoc} />

          <Route path="/cloud/functions" component={SWXDoc} />

          <Route path="/cloud/edge-ops">
            <Switch>
              <Route
                path="/cloud/edge-ops/cluster-management"
                component={SWXDoc}
              />
              <Route
                path="/cloud/edge-ops/cluster-configuration"
                component={SWXDoc}
              />
              <Route
                path="/cloud/edge-ops/installer-images"
                component={SWXDoc}
              />

              <Redirect to={"/cloud/edge-ops/cluster-management"} />
            </Switch>
          </Route>

          <Route path="/cloud/access-control">
            <Switch>
              <Route
                exact
                path="/cloud/access-control/users"
                component={SWXDoc}
              />
              <Route
                exact
                path="/cloud/access-control/roles"
                component={SWXDoc}
              />
              <Route
                exact
                path="/cloud/access-control/apps"
                component={SWXDoc}
              />
              <Route
                exact
                path="/cloud/access-control/login"
                component={SWXDoc}
              />

              <Redirect to={"/cloud/access-control/users"} />
            </Switch>
          </Route>

          <Route path="/cloud/spaces" component={SWXDoc} />

          <Route path="/cloud/object-storage" component={SWXDoc} />

          <Route path="/cloud/labels" component={SWXDoc} />

          <Route path="/cloud/mqttTopics" component={MQTTtopics} />

          <Route path="/cloud/spaceSettings" component={SWXDoc}>
            <Switch>
              <Route path="/cloud/spaceSettings/secrets" component={SWXDoc} />
              <Route path="/cloud/spaceSettings/variables" component={SWXDoc} />
              <Redirect to="/cloud/spaceSettings/variables" />
            </Switch>
          </Route>

          <Redirect to={"/cloud/anything-db"} />
        </Switch>
      </CloudApi>
    </Route>

    <Route path="/ecp">
      <EcpApi>
        <Switch>
          <Route path="/ecp/auth" component={SWXDoc}></Route>
          <Route path="/ecp/export-mqtt" component={SWXDoc}></Route>
          <Route path="/ecp/export-google-pubsub" component={SWXDoc}></Route>
          <Route path="/ecp/health" component={SWXDoc}></Route>
          <Route path="/ecp/logging" component={SWXDoc}></Route>
          <Route path="/ecp/reports" component={SWXDoc}></Route>
          <Route path="/ecp/labels" component={SWXDoc}></Route>
          <Route path="/ecp/things" component={SWXDoc}></Route>
          <Route path="/ecp/cloud-connect" component={SWXDoc}></Route>
          <Route path="/ecp/drivers-opcua" component={SWXDoc}></Route>
          <Route path="/ecp/drivers-lorawan" component={SWXDoc}></Route>
          <Route path="/ecp/drivers-mqtt" component={SWXDoc}></Route>
          <Route path="/ecp/support-historic-data" component={SWXDoc}></Route>
          <Route path="/ecp/support-rapidminer" component={SWXDoc}></Route>
          <Route path="/ecp/support-seldon" component={SWXDoc}></Route>
          <Route path="/ecp/support-smartworks" component={SWXDoc}></Route>
          <Route path="/ecp/support-iot-studio" component={SWXDoc}></Route>
          <Route path="/ecp/support-rules" component={SWXDoc}></Route>
          <Route path="/ecp/network-configuration" component={SWXDoc}></Route>

          <Redirect to={"/ecp/auth"} />
        </Switch>
      </EcpApi>
    </Route>

    <Redirect to={"/cloud"} />
  </Switch>
);
