import React from "react"

export const scopesPreset = (system) => {
  // Variable to capture the security prop of OperationSummary
  // then pass it to authorizeOperationBtn
  let currentSecurity
  return {
    wrapComponents: {
      // Wrap OperationSummary component to get its prop
      OperationSummary: Original => props => {
          const security = props.operationProps.get('security')
          currentSecurity = security.toJS()
          return <Original {...props} />
      },
      // Wrap the padlock button to show the
      // scopes required for current operation
      authorizeOperationBtn: Original =>
        function (props) {
          const securityScheme = currentSecurity?.find(s => Object.values(s)[0].length > 0) ?? {}
          const requiredScopes = Object.values(securityScheme)[0]
          return (
            <div className="right-content" style={{ display: "flex" }}>
              {
                requiredScopes && (
                  <div style={scopesStyles}>
                    <span>
                      <b>{"Required Scopes: "}</b>
                      <code>
                        {requiredScopes.join(", ")}
                      </code>
                    </span>
                  </div>
                )
              }
              <Original {...props}/>
            </div>
          )
        },
    },
  }
}

const scopesStyles = {
  display: "flex",
  flexDirection: "column",
  fontSize: 14,
  justifyContent: "center",
  marginLeft: 8,
  width: 140,
}
