import React from 'react';
import AppContext from 'components/AppContext/AppContext'

const navConfig = {
  top: [
    {
      key: "ecp/core-services",
      label: "Core Services",
      icon: "unity:compass",
      children: [
        {
          key: "ecp/auth",
          label: "Auth",
          icon: "unity:token",
        },
        {
          key: "ecp/cloud-connect",
          label: "Cloud Connect",
          icon: "unity:db_mqtt",
        },
        {
          key: "ecp/health",
          label: "Health",
          icon: "unity:load_profile",
        },
        {
          key: "ecp/logging",
          label: "Logging",
          icon: "unity:document",
        },
        {
          key: "ecp/reports",
          label: "Reports",
          icon: "unity:form_chart",
        },
        {
          key: "ecp/things",
          label: "Things",
          icon: "unity:cube",
        },
      ]
    },
    {
      key: "ecp/device-drivers",
      label: "Device Drivers",
      icon: "unity:adv_scoring",
      children: [
        {
          key: "ecp/drivers-opcua",
          label: "OPC-UA",
          icon: "unity:exchange_doc",
        },
        {
          key: "ecp/drivers-lorawan",
          label: "LoRaWAN",
          icon: "unity:exchange_doc",
        },
        {
          key: "ecp/drivers-mqtt",
          label: "MQTT",
          icon: "unity:exchange_doc",
        },
      ]
    },
    {
      key: "ecp/export-services",
      label: "Export Services",
      icon: "unity:file_upload",
      children: [
        {
          key: "ecp/export-mqtt",
          label: "MQTT",
          icon: "unity:message",
        },
        {
          key: "ecp/export-google-pubsub",
          label: "Google Pubsub",
          icon: "unity:message",
        }
      ]
    },
    {
      key: "ecp/support-services",
      label: "Support Services",
      icon: "unity:help",
      children: [
        {
          key: "ecp/support-historic-data",
          label: "Historic Data",
          icon: "unity:cube",
        },
        // {
        //   key: "ecp/support-smartworks",
        //   label: "SmartWorks Sync",
        //   icon: "unity:relate",
        // },
        {
          key: "ecp/support-iot-studio",
          label: "IoT Studio Sync",
          icon: "unity:relate",
        },
        {
          key: "ecp/support-rapidminer",
          label: "RapidMiner",
          icon: "unity:cube_1",
        },
        {
          key: "ecp/support-rules",
          label: "Rules",
          icon: "unity:schedule"
        },
        // {
        //   key: "ecp/support-seldon",
        //   label: "Seldon",
        //   icon: "unity:cube_1",
        // },
        // {
        //   key: "ecp/network-configuration",
        //   label: "Network Configuration",
        //   icon: "unity:cube_1",
        // },
      ]
    }
  ],
  bottom: [
    {
      key: "cloud",
      label: "Cloud Docs",
      icon: "unity:document",
      short: true
    }
  ]
}

const EcpApi = ({children}) => {
  return (
      <AppContext
        navConfig={navConfig}
        title="ECP User Manual"
      >
        {children}
      </AppContext>
    );
}
export default EcpApi