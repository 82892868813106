import React from 'react'
import Routes from './routes'
import { unityVars } from './assets/stylesheets/common'
import { NotificationsHandler } from './components/Notifications/NotificationsHandler'
import { GLOBAL_NOTIFICATIONS } from './utils/notifications'
import { BrowserRouter as Router } from 'react-router-dom'

const Root = () => (
  <Router>
    <div style={{...unityVars, ...styles.container}}>
      <NotificationsHandler
        target={GLOBAL_NOTIFICATIONS}
        containerStyle={{display: 'flex', height: '100%'}}
      >
        { Routes }
      </NotificationsHandler>
    </div>
  </Router>
)

export default Root

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    maxWidth: '100vw'
  }
}
