//  === IMPORTS ===
// Modules
import React, { Component } from 'react'

// Components
import {
  UnityNotificationsHandler,
  UnityNotificationsSplitPane
} from '@bit/smartworks.unity-react.unity-core-react'

// Styles
import { colors } from '../../assets/stylesheets/common'

const notificationThemeColors = {
  error: colors.redOrange,
  warning: colors.yellow,
  success: colors.green,
  help: colors.skyBlue,
  tip: colors.skyBlueShade
}

const notificationThemeIcons = {}

export class NotificationsHandler extends Component {
  render() {
    const {
      containerStyle={},
      children,
      ...otherProps
    } = this.props

    return (
      <UnityNotificationsHandler
        colors={notificationThemeColors}
        icons={notificationThemeIcons}
        {...otherProps}
      >
          <div
            style={containerStyle}
          >
            {children}
          </div>
      </UnityNotificationsHandler>
    )
  }
}

export class NotificationsSplitPane extends Component {
  render() {
    return <UnityNotificationsSplitPane
      colors={notificationThemeColors}
      icons={notificationThemeIcons}
      {...this.props}
    />
  }
}